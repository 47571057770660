<script lang="ts">
	import { run } from 'svelte/legacy';

	import { apiFetch, DeprecatedFetch_useapiFetch } from '$lib/utils/fetch';

	import { cn } from '$lib/utils/utils';
	import { goto } from '$app/navigation';
	import LoginButton from './LoginButton.svelte';
	import Card from './Card.svelte';
	import Button from '../ui/Button.svelte';
	import { page } from '$app/stores';
	import { closeModal, openModal } from 'svelte-modals';
	import Modal from '$lib/components/Modal.svelte';
	import Login from './Login.svelte';
	import { user } from '$lib/stores/user';

	import { superForm, setMessage, setError, defaults, superValidate } from 'sveltekit-superforms';
	import { zod } from 'sveltekit-superforms/adapters';

	let username: string = $state('');
	let password: string = $state('');
	let email: string = $state('');
	let social: string = $state('');
	let number: string = $state('');
	let user_agreement: boolean = $state(false);
	let playpark_stadgar: boolean = $state(false);
	let sverok_agreement: boolean = $state(false);

	let login_password: string = $state('');
	let login_username: string = $state('');

	let modalStyle: string = $state('');
	let isLoading: boolean = $state(false);
	let defaultStyle = 'p-2 border rounded-md border-zinc-800 w-full flex justify-center';

	let registerMessage: string = $state('');

	import * as z from 'zod';
	import FormText from '../input/FormText.svelte';
	import FormCheckbox from '../input/FormCheckbox.svelte';

	function validateSwedishSSNChecksum(ssn: string): boolean {
		const digits = ssn.replace(/\D/g, '');
		let sum = 0;
		for (let i = 0; i < 9; i++) {
			let digit = parseInt(digits[i]);
			if (i % 2 === 0) {
				digit *= 2;
				if (digit > 9) {
					digit -= 9;
				}
			}
			sum += digit;
		}
		const checksum = (10 - (sum % 10)) % 10;
		return checksum === parseInt(digits[9]);
	}

	const schema = z.object({
		username: z.string().min(2, {
			message: 'Användarnamnet måste vara minst 2 tecken långt'
		}),
		email: z.string().email({
			message: 'E-postadressen måste vara giltig'
		}),
		password: z.string().min(8, {
			message: 'Lösenordet måste vara minst 8 tecken långt'
		}),
		social: z
			.string()
			.min(12, {
				message: 'Personnumret måste vara minst 12 tecken långt'
			})
			.max(12, { message: 'Personnumret får max vara 12 tecken långt' })
			.refine(
				(value) => {
					// Basic format check (YYYYMMDD-XXXX or YYMMDD-XXXX)
					const regex = /^(\d{6}|\d{8})[-+]?\d{4}$/;
					if (!regex.test(value)) {
						return false;
					}

					// Extract date parts
					const parts = value.replace(/\D/g, '');
					const year = parts.length === 12 ? parts.slice(0, 4) : `19${parts.slice(0, 2)}`;
					const month = parts.slice(-8, -6);
					const day = parts.slice(-6, -4);

					// Validate date
					const date = new Date(`${year}-${month}-${day}`);
					if (isNaN(date.getTime())) {
						return false;
					}

					// Validate checksum
					return validateSwedishSSNChecksum(parts.slice(-10));
				},
				{
					message: 'Ogiltigt personnummer'
				}
			),
		number: z
			.string()
			.min(10, {
				message: 'Telefonnumret måste vara minst 10 tecken långt'
			})
			.max(10, { message: 'Telefonnumret får max vara 10 tecken långt' }),
		user_agreement: z.literal(true, {
			errorMap: (err, ctx) => {
				switch (err.code) {
					case 'invalid_literal':
						return { message: 'Du måste godkänna användaravtalet' };
				}
				return { message: ctx.defaultError };
			}
		}),
		playpark_stadgar: z.literal(true, {
			errorMap: (err, ctx) => {
				switch (err.code) {
					case 'invalid_literal':
						return { message: 'Du måste godkänna Playparks stadgar' };
				}
				return { message: ctx.defaultError };
			}
		}),
		sverok_agreement: z.literal(true, {
			errorMap: (err, ctx) => {
				switch (err.code) {
					case 'invalid_literal':
						return { message: 'Du måste godkänna Sveroks hantering av personuppgifter' };
				}
				return { message: ctx.defaultError };
			}
		})
	});

	// const { form, errors, data } = createForm<z.infer<typeof schema>>({
	// 	onError(errors) {
	// 		console.log(errors);
	// 	},
	// 	onSubmit(values) {
	// 		username = globalThis.$data.username;
	// 		password = globalThis.$data.password;
	// 		email = globalThis.$data.email;
	// 		social = globalThis.$data.social;
	// 		number = globalThis.$data.number;
	// 		user_agreement = globalThis.$data.user_agreement;
	// 		playpark_stadgar = globalThis.$data.playpark_stadgar;
	// 		sverok_agreement = globalThis.$data.sverok_agreement;
	// 		handleRegister();
	// 	},
	// 	extend: [validator({ schema }), reporter]
	// });

	const { form, errors, message, constraints, enhance, validateForm, validate } = superForm(
		defaults(zod(schema)),
		{
			SPA: true,
			resetForm: false,
			validators: zod(schema),
			onUpdate({ form }) {
				// Form validation
				validateForm({ update: true });
				if (form.valid) {
					username = form.data.username;
					password = form.data.password;
					email = form.data.email;
					social = form.data.social;
					number = form.data.number;
					user_agreement = form.data.user_agreement;
					playpark_stadgar = form.data.playpark_stadgar;
					sverok_agreement = form.data.sverok_agreement;
					handleRegister();
				}
			}
		}
	);

	async function openLogin() {
		if ($page.url.pathname.includes('register')) {
			goto('/login');
		} else {
			closeModal();
			openModal(Modal, {
				component: Login,
				props: null
			});
		}
	}

	const handleRegister = async () => {
		registerMessage = '';
		isLoading = true;

		await apiFetch('/auth/register', {
			method: 'POST',
			body: {
				username: username,
				password: password,
				email: email,
				personnummer: social,
				telefonnummer: number,
				user_agreement,
				playpark_stadgar,
				sverok_agreement
			}
		})
			.then((data) => {
				registerMessage = 'Registrerad!';
				modalStyle = 'border-green-500 bg-green-500/30';
				isLoading = false;
				login_username = username;
				login_password = password;
				setTimeout(() => {
					loginAfterRegister();
				}, 6000);
			})
			.catch((err) => {
				isLoading = false;
				// Log all keys of error
				let errorData;
				if (typeof err.data === 'string') {
					try {
						errorData = JSON.parse(err.data);
					} catch (e) {
						console.error('Failed to parse error data:', err.data);
						errorData = { message: err.data };
					}
				} else {
					errorData = err.data;
				}
				if (errorData?.errors?.ValidationError) {
					const validation_errors = errorData.errors.ValidationError;
					Object.entries(validation_errors).forEach(([errorKey, errorMessage]) => {
						const errorFlag =
							errorKey === 'personnummer'
								? 'social'
								: errorKey === 'telefonnummer'
									? 'number'
									: errorKey;

						$errors[errorFlag as keyof typeof $errors] = [String(errorMessage)];
					});
				} else {
					registerMessage = 'Något gick fel med registreringen, kontakta oss för hjälp';
					modalStyle = 'border-red-500 bg-red-500/30';
				}

				// modalStyle = 'border-red-500 bg-red-500/30';
			});

		return;
	};

	const loginAfterRegister = async () => {
		isLoading = true;
		modalStyle = 'border-orange-500 bg-orange-500/30';
		registerMessage = 'Loggar in...';
		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/login',
			method: 'POST',
			body: { username: login_username, password: login_password }
		})
			.then((data) => {
				isLoading = false;

				modalStyle = 'border-green-500 bg-green-500/30';
				user.set(JSON.parse(JSON.stringify(data.data)));
				setTimeout(() => {
					goto('/');
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
				isLoading = false;
				registerMessage = 'Något gick fel med att logga in, försök igen senare';
				modalStyle = 'border-red-500 bg-red-500/30';
			});
	};
</script>

<Card class="overflow-scroll">
	<div>
		<h1 class="text-3xl font-semibold text-left text-white">Bli medlem</h1>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Redan medlem?</h4>
			<Button functionToRun={openLogin}>Logga in</Button>
		</span>
	</div>

	<div class="flex flex-col items-center justify-center space-y-5">
		{#if registerMessage}
			<p class={cn(defaultStyle, modalStyle)}>{registerMessage}</p>
		{/if}
		<form class="flex flex-col w-full space-y-3" use:enhance>
			<FormText
				name="username"
				label="Användarnamn"
				bind:error={$errors.username}
				bind:value={$form.username}
			/>
			<FormText name="email" label="E-post" bind:error={$errors.email} bind:value={$form.email} />
			<FormText
				name="password"
				label="Lösenord"
				type="password"
				bind:error={$errors.password}
				bind:value={$form.password}
			/>
			<FormText
				name="social"
				label="Personnummer"
				bind:error={$errors.social}
				bind:value={$form.social}
			/>
			<FormText
				name="number"
				label="Telefonnummer"
				bind:error={$errors.number}
				bind:value={$form.number}
			/>
			<!-- Accept all agreements -->
			<div class="flex flex-col gap-y-3">
				<span>
					<FormCheckbox
						label_for="user_agreement"
						name="user_agreement"
						bind:error={$errors.user_agreement}
						bind:checked={$form.user_agreement}
					>
						Jag godkänner <a href="/terms" class="text-blue-400 underline">användaravtalet</a>
					</FormCheckbox>
				</span>
				<span>
					<FormCheckbox
						label_for="playpark_stadgar"
						name="playpark_stadgar"
						bind:error={$errors.playpark_stadgar}
						bind:checked={$form.playpark_stadgar}
					>
						Jag godkänner <a href="/stadgar" class="text-blue-400 underline">Playparks stadgar</a> och
						att jag blir medlem i Playpark spelförening
					</FormCheckbox>
				</span>
				<span>
					<FormCheckbox
						label_for="sverok_agreement"
						name="sverok_agreement"
						bind:error={$errors.sverok_agreement}
						bind:checked={$form.sverok_agreement}
					>
						Jag godkänner hur <a href="/sverok" class="text-blue-400 underline"
							>Sverok hanterar mina personuppgifter</a
						>
					</FormCheckbox>
				</span>
			</div>
			<div class="h-full">
				<LoginButton button_state={isLoading ? 'loading' : 'default'} type="submit">
					<div class="relative flex items-center justify-center w-full px-4">Registrera</div>
				</LoginButton>
			</div>
		</form>
	</div>
</Card>

<style>
</style>
